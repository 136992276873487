import { render, staticRenderFns } from "./ItemsAndAccountBased.vue?vue&type=template&id=47f3c1b7&scoped=true&"
import script from "./ItemsAndAccountBased.vue?vue&type=script&lang=js&"
export * from "./ItemsAndAccountBased.vue?vue&type=script&lang=js&"
import style0 from "./ItemsAndAccountBased.vue?vue&type=style&index=0&id=47f3c1b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f3c1b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VApp,VCard,VDivider,VMain,VTab,VTabItem,VTabs,VTabsItems})
