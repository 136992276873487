<template>
  <div class="widgetContainer__body_">
    <!-- <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">{{ $t('side_menu_items') }}</p>
      </div>
    </div> -->
    <v-data-table
      :headers="productTableHeader"
      :items="tableData"
      class="elevation-1 v-table-middle-align"
      :single-select="false"
      show-select
      v-model="selectedProducts"
      :loading="isCategoryBasedItemDataLoading"
      disable-pagination
      :hide-default-footer="true"
    >
      <template v-slot:top>
        <div class="d-md-flex w-100 pt-4 px-4 pb-3 border-bottom">
          <div class="d-md-flex justify-md-end my-2 my-md-0 mx-md-0">
            <div class="d-flex">
              <v-menu offset-y :close-on-content-click="false" v-model="showFilter">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" outlined large height="40" class="text-capitalize" color="primary">
                    {{ $t('filters') }}
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-card width="300" class="pt-2">
                  <v-card-text>
                    <label for="filter-enabled" class="primary--text">Status</label>
                    <v-select
                      id="filter-enabled"
                      v-model="isEnabled"
                      solo
                      :items="['Active', 'In-Active']"
                      flat
                      outlined
                      dense
                      color="primary"
                      hide-details
                    >                              
                    </v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      text
                      link
                      @click="fetchCategoryBasedItemData"
                    >
                      {{ $t('ok') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <div class="search-field-wrapper ml-2">
                <v-text-field
                  v-model="searchText"
                  :label="$t('search_items')"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  hide-details
                  flat
                  solo
                  outlined
                  dense
                  class=""
                ></v-text-field>
              </div>
            </div>
            <div class="d-md-block d-none">
              <v-btn
                class="text-capitalize ml-md-2"
                color="primary"
                @click="onToggleItemForm"
                height="38"
              >
                {{ $t('add_account_based_item') }}
              </v-btn>
            </div>
            <div class="d-sm-block d-md-none mt-2">
              <v-btn
                class="text-capitalize ml-md-2 d-block"
                width="100%"
                color="primary"
                @click="onToggleItemForm"
                height="38"
              >
                {{ $t('add_account_based_item') }}
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.category="{ item }">
        {{ item.category.data.name }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined small>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              @click="onSelectEditItemData(item)"
            >
              <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="onSelectItemInformation(item)"
            >
              <v-list-item-title>{{ $t('view') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="onDuplicateItem(item)"
            >
              <v-list-item-title>{{ $t('duplicate') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="onSelectItemToDisable(item)"
            >
              <v-list-item-title class="text-danger">{{ $t('disable') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:no-data>
        <v-alert>
          <span class="subtitle-2">{{ $t('no_data') }}</span>
          <br />
          <v-btn outlined text small @click="onToggleItemForm" color="#0889a0" class="mb-2 font-weight-bold py-4 text-capitalize">
            <v-icon>mdi-plus</v-icon>
            {{ $t('add_account_based_item') }}
          </v-btn>
        </v-alert>
      </template>
      <template v-slot:foot>
        <tfoot>
          <tr>
            <td colspan="7" class="text-right">
              <div class="d-flex justify-end">
                <!-- <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{pagination.current_page || 0}} - {{ pagination.total_pages || 0 }} {{ $t('of') }} {{ (pagination.total || 0).toLocaleString() }} <span class="d-sm-inline d-none">{{ $t('records') }}</span></h6> -->
                <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{ (pagination.total || 0).toLocaleString() }} <span class="">{{ $t('records') }}</span></h6>
                <v-spacer/>
                <div class="select-page-limit">
                  <v-select
                    v-model="pageLimit"
                    :items="[10, 20, 25]"
                    label="Per page"
                    hide-details
                    dense
                    flat
                    solo
                    @change="onChangePageLimit"
                  ></v-select>
                </div>
                <pagination
                    :total="pagination.total_pages || 1"
                    :current-page="currentPage"
                    :total-visible="10"
                    @pageSelected="onPaginationClick"
                  >
                  </pagination>
              </div>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
    <v-snackbar
      timeout="2000"
      vertical
      :color="snackbarOption.color"
      v-model="showSnackbar"
      bottom
    >
      <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
    </v-snackbar>
    <account-base-item-form :open="showFormDialog" :is-edit-mode="isEditMode" :form-values="selectedItemData" @toggle="onToggleItemForm"></account-base-item-form>
    <account-based-item-disable :open="showDisableModal" :form-data="selectedDataToDisable" @toggle="onToggleAccountBasedItem"></account-based-item-disable>
    <v-dialog
      v-model="showInfoModal"
      max-width="980"
      origin="top center"
      class="dialog pa-0"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar light elevation="1" class="">
            <v-toolbar-title>{{ selectedItemInformation.name }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn class="d-none d-sm-flex" color="primary" @click="onSelectEditItemData(selectedItemInformation)">
              {{  $t('Edit') }}
            </v-btn>
            <v-btn text outlined class="ml-1 mr-4 d-none d-sm-flex" @click="onSelectItemToDisable(selectedItemInformation)">
              {{ $t('disable') }}                    
            </v-btn>
            <v-btn icon class="d-none d-sm-flex" @click="onToggleItemInformationModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="py-4 pb-8">
          <v-row>
            <v-col md="3" lg="3" cols="12">
              <h6 class="subtitle-1 labelcolor--text">{{ $t('item_description') }}</h6>
              <span>{{ selectedItemInformation.description }}</span>
            </v-col>
            <v-col md="3" lg="3" cols="12">
              <h6 class="subtitle-1 labelcolor--text">{{ $t('category') }}</h6>
              <span>{{ selectedItemInformation.category_id ? selectedItemInformation.category.data.name : '' }}</span>
            </v-col>                  
          </v-row>
          <v-row>
            <v-col md="3" lg="3" cols="12">
              <h6 class="subtitle-1 labelcolor--text">{{ $t('sales_price') }}</h6>
              <span>{{ selectedItemInformation.sale_price_formatted }}</span>
            </v-col>
            <v-col md="3" lg="3" cols="12">
              <h6 class="subtitle-1 labelcolor--text">{{ $t('income_account') }}</h6>
              <span>{{ de_income_account }}</span>
            </v-col>
            <v-col md="3" lg="3" cols="12">
              <h6 class="subtitle-1 labelcolor--text">{{ $t('purchase_price') }}</h6>
              <span>{{ selectedItemInformation.purchase_price_formatted }}</span>
            </v-col>
            <v-col md="3" lg="3" cols="12">
              <h6 class="subtitle-1 labelcolor--text">{{ $t('expense_account') }}</h6>
              <span>{{ de_expense_account }}</span>
            </v-col>
          </v-row>

          <div v-if="isQBOEnabled === 1" class="mt-2">
            <hr />
            <h2 class="header-2 font-weight-bold mt-3 grey--text text--darken-2">{{ $t('quickbooks_information') }}</h2>
            <hr />
            <v-row>
              <v-col md="3" lg="3" cols="12">
                <h6 class="subtitle-1 labelcolor--text">{{ $t('qbo_item_type') }}</h6>
                <span>{{ selectedItemInformation.qbo_item_type }}</span>
              </v-col>
              <v-col md="3" lg="3" cols="12">
                <h6 class="subtitle-1 labelcolor--text">{{ $t('qbo_account_type') }}</h6>
                <span>{{ qbo_account_type }}</span>
              </v-col>
              <v-col md="3" lg="3" cols="12">
                <h6 class="subtitle-1 labelcolor--text">{{ $t('detail_type') }}</h6>
                <span>{{ detail_type }}</span>
              </v-col>
              <v-col md="3" lg="3" cols="12">
                <h6 class="subtitle-1 labelcolor--text">{{ $t('parent_account') }}</h6>
                <span>{{ parent_account }}</span>
              </v-col>
              <v-col md="3" lg="3" cols="12">
                <h6 class="subtitle-1 labelcolor--text">{{ $t('currencyname') }}</h6>
                <span>{{ selected_item_currency }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider class="d-flex d-sm-none"/>
        <v-card-actions class="d-flex d-sm-none">
          <v-btn color="primary" @click="onSelectEditItemData(selectedItemInformation)">
              {{  $t('Edit') }}
            </v-btn>
            <v-btn text outlined class="ml-1 mr-4" @click="onSelectItemToDisable(selectedItemInformation)">
              {{ $t('disable') }}                    
            </v-btn>
            <v-spacer/>
            <v-btn @click="onToggleItemInformationModal" elevation="0">
              close
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import AccountBaseItemForm from '../components/AccountBaseditemForm.vue';
import AccountBasedItemDisable from '../components/AccountBasedItemDisableModal.vue';
import Pagination from '../components/Pagination.vue';
import { apiErrorMessage, debounce } from '@/utils/general';

export default {
  components: {
    AccountBaseItemForm,
    AccountBasedItemDisable,
    Pagination,
  },

  data() {
    return {
      page: 1,
      currentPage: 1,
      showDisableModal: false,
      selectedItemData: {},
      selectedDataToDisable: {},
      isEditMode: false,
      searchText: '',
      selectedProducts: [],
      serviceTypeSelected: '',
      showFormDialog: false,
      showSnackbar: false,
			snackbarOption: {
				icon: '',
				message: '',
				color: ''
			},
      itemsData: [],
      pageLimit: 10,
      isCategoryBasedItemDataLoading: true,
      selectedItemInformation: {},
      showInfoModal: false,
      isEnabled: 'Active',
      showFilter: false,
    };
  },

  created() {
    this.fetchCategoryBasedItemData();
  },

  computed: {
    ...mapGetters('accounting', ['isQBOEnabled']),
    tableData: {
      get(){
        return this.itemsData?.data || []; 
      }
    },

    productTableHeader() {
      return [
        {
          text: this.$t('name'),
          value: "name",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('description'),
          value: "description",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('category'),
          value: "category",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('sales_price'),
          value: "sale_price_formatted",
          class: "text-uppercase th--text font-weight-bold"
        },
        {
          text: this.$t('purchase_price'),
          value: "purchase_price_formatted",
          class: "text-uppercase th--text font-weight-bold"
        },
        { text: "", value: "actions", sortable: false },
      ];
    },

    pagination: {
      get() {
        return this.itemsData.data ? this.itemsData.meta.pagination : {};
      }
    },

    de_income_account: {
      get() {
        return this.selectedItemInformation.de_income_account_value && Object.values(JSON.parse(this.selectedItemInformation.de_income_account_value)).join('') || '';
      }
    },

    de_expense_account: {
      get() {
        return this.selectedItemInformation.de_expense_account_value && Object.values(JSON.parse(this.selectedItemInformation.de_expense_account_value)).join('') || '';
      }
    },

    qbo_reference() {
      return this.selectedItemInformation.qbo_reference ? JSON.parse(this.selectedItemInformation.qbo_reference) : {};
    },

    qbo_account_type: {
      get() {
        return this.qbo_reference['AccFieldRef']?.qbo_account_type || '';
      }
    },

    parent_account: {
      get() {
        return this.qbo_reference['AccFieldRef']?.parent_account?.Name || '';
      }
    },

    detail_type: {
      get() {
        return this.qbo_reference['AccFieldRef']?.qbo_sub_account_type?.name || '';
      }
    },

    qbo_asset_account: {
      get() {
        return this.selectedItemInformation.qbo_asset_account ? JSON.parse(this.selectedItemInformation.qbo_asset_account)['Name'] : '';
      }
    },

    selected_item_currency() {
      return this.qbo_reference['AccFieldRef']?.currency_values?.name || '';
    },

  },

  watch: {
    searchText: debounce(function() {
      this.fetchCategoryBasedItemData();
    }, 300)
  },

  methods: {
    ...mapActions('accounting', ['getCategoryBasedItemData']),

    async fetchCategoryBasedItemData() {
      this.showFilter = false;
      try {
        this.isCategoryBasedItemDataLoading = true;
        const data = await this.getCategoryBasedItemData({
          page: this.currentPage,
          limit: this.pageLimit,
          search: this.searchText || '',
          enabled: this.isEnabled === 'Active' ? 1 : 0,
        });
        this.itemsData = data;
        this.isCategoryBasedItemDataLoading = false;
      } catch(error) {
        this.isCategoryBasedItemDataLoading = false;
        apiErrorMessage(error);
      }
    },

    onPaginationClick(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchCategoryBasedItemData();
    },

    onChangePageLimit() {
      this.currentPage = 1;
      this.fetchCategoryBasedItemData();
    },
    
    onToggleItemForm(options  = {}) {
      this.isEditMode = false;
      this.selectedItemData = {};
      this.showFormDialog = !this.showFormDialog;
      if(options.created || options.updated) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'success',
					message: options.message,
				};
        this.showInfoModal = false;
        this.selectedItemInformation = {};
				this.showSnackbar = true;
        this.fetchCategoryBasedItemData();
      }
    },

    onSelectItemToDisable(data = {}) {
      this.selectedDataToDisable = JSON.parse(JSON.stringify(data));
      this.onToggleAccountBasedItem();
    },

    onSelectEditItemData(data = {}) {
      this.selectedItemData = JSON.parse(JSON.stringify(data));
      this.isEditMode = true;
      this.showFormDialog = true;
    },

    onSelectItemInformation(data = {}) {
    this.selectedItemInformation = data;
      this.onToggleItemInformationModal();
    },

    onToggleItemInformationModal() {
      this.showInfoModal = !this.showInfoModal;
      if(!this.showInfoModal) {
        this.selectedItemInformation = {};
      }
    },

    onToggleAccountBasedItem(options = {}) {
      this.showDisableModal = !this.showDisableModal;

      if(options.disabled) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'red',
					message: options.message || 'Disabled.',
				};
        this.showInfoModal = false;
        this.selectedItemInformation = {};
				this.showSnackbar = true;

        this.fetchCategoryBasedItemData();
      }
    },

    onDuplicateItem(data) {
      this.selectedItemData = JSON.parse(JSON.stringify(data));

      this.showFormDialog = true;
      this.isEditMode = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  $form-color: #0889a0;
  $btn-active-color: #0171a1;
  $text-muted-color: #6d858f;
  $text-danger-color: red;

  .vue-app {
    background: none;
  }

  .form-label {
    color: $form-color;
  }

  .form-border {
    border: 1px solid;
    border-color: $form-color !important;
  }

  .btn-active-color:active {
    background-color: $btn-active-color;
    color: #fff;
  }

  .text-muted {
    color: $text-muted-color;
  }

  .v-btn.v-item--active.v-btn--active {
    color: $btn-active-color;
  }

  .text-danger {
    color: $text-danger-color;
  }

  .v-list-item {
    min-height: 36px;
  }
  .btn-primary {
    background-color: $btn-active-color !important;
    color: #ffffff !important;
  }
  .w-100 {
    width: 100%;
  }

  .v-snack__wrapper {
    max-width: none;
  }

  .v-input__control {
    min-height: 20px !important;
  }

  .border-bottom {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

</style>

<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
  .select-page-limit{
    max-width: 80px;
  }
  .dialog {
		z-index: 999;
	}
</style>