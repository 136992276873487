<template>
  <div class="widgetContainer__body">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">{{ $t('side_menu_items') }}</p>
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-card>
          <v-tabs v-model="tab" height="56">
            <v-tab>{{ $t('side_menu_product_services') }}</v-tab>
            <v-tab v-if="isQBOEnabled">{{ $t('account_based_item') }}</v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <items></items>
            </v-tab-item>
            <v-tab-item>
              <account-based></account-based>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-main>
    </v-app>
    <!-- <v-btn absolute bottom right dark style="right: 20px">test</v-btn> -->
  </div>
</template>

<script>

import Items from './Items.vue';
import AccountBased from './AccountBasedItems.vue';
import { mapGetters } from 'vuex';


export default {
	components: {
    Items,
    AccountBased,
	},
  created() {
    //
  },
  data() {
    return {
      tab: false
    };
  },
  computed: {
    ...mapGetters("accounting", ["isQBOEnabled"]),
  },
  methods: {
    //
  }

};
</script>
<style lang="scss" scoped>
.vue-app {
  background: none;
}

</style>