<template>
  <div class="">
	<v-dialog
	  v-model="showDialog"
	  max-width="1200"
	  origin="top center"
	  class="dialog pa-0"
		persistent
		scrollable
	>
	  <v-card :loading="isSaving">
		<v-card-title class="pa-0 z-index-front">
		  <v-toolbar light elevation="1" class="">
				<v-toolbar-title>{{ isEditMode ? $t('edit_item') : $t('add_item') }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="onClose">
					<v-icon>mdi-close</v-icon>
				</v-btn>
		  </v-toolbar>
		</v-card-title>

		<v-card-text class="px-0 pb-0">
			<v-container class="grey lighten-4 pa-0">
				<v-form
					ref="itemForm"
					lazy-validation
					v-model="formValid"
				>
					<v-row
						no-gutters
					>
						<v-col
							md="5" cols="12"
							class="pa-4"
						>
							<v-card
								class="pa-2 grey lighten-4"
								flat
							>

								<label class="form-label text-uppercase" for="formdata-item-name">{{ $t('name') }}</label>
								<v-text-field
									v-model="formData.name"
									:label="$t('type_item_name')"
									:rules="[v => !!v || $t('field_required')]"
									solo
									outlined
									flat
									required
									dense
									id="formdata-item-name"
								></v-text-field>

								<label class="form-label text-uppercase" for="formdata-item-sku">{{ $t('sku') }}</label>
								<v-text-field
									v-model="formData.sku"
									:label="$t('sku')"
									solo
									outlined
									flat
									required
									dense
									id="formdata-item-sku"
								></v-text-field>

								<label class="form-label text-uppercase" for="formdata-category">{{ $t('category') }}</label>
								<v-select
									v-model="formData.category"
									:items="categoryData"
									id="formdata-category"
									:label="$t('select_category')"
									solo
									flat
									outlined
									dense
								>
								</v-select>

								<label class="form-label text-uppercase" for="formdata-item-description">{{ $t('item_description') }}</label>
								<v-textarea
									solo
									:label="$t('type_description_item')+'...'"
									outlined
									flat
									id="formdata-item-description"
									rows="2"
									v-model="formData.description"
								></v-textarea>
								
							</v-card>
						</v-col>
						<v-col
							class="white pa-4"
						>
							<v-card
								class="pa-2"
								flat
							>
							<fieldset class="px-5 rounded mb-4 pb-4" v-if="isQBOEnabled === 1">
									<legend class="rounded pa-2 mb-4">
										{{ $t('quickbooks_information') }}
									</legend>
									<v-row>
										<v-col md="6" cols="12">
											<div class="d-flex align-center justify-space-between">
												<label class="form-label text-uppercase" for="formdata-qbo-item-type">{{ $t('qbo_item_type') }}</label>
												<v-icon @click="onChangeQBOItem" v-if="QBOIncomeAccountData.length === 0 || QBOExpenseAccountData.length === 0">mdi-refresh</v-icon>
											</div>
											<v-select
												v-model="formData.qbo_item_type"
												:items="QBOItemTypeData"
												:rules="[v => !!v || $t('field_required')]"
												id="formdata-qbo-item-type"
												label=""
												solo
												flat
												outlined
												dense
												hide-details
												@change="onChangeQBOItem"
												:loading="isQBOAccountLoading"
												:disabled="isQBOAccountLoading"
											>
											</v-select>
										</v-col>
										<v-col md="6" cols="12">
											<label class="form-label text-uppercase" for="formdata-qbo-income-account">{{ $t('qbo_income_account') }}</label>
											<v-autocomplete
												v-model="formData.qbo_income_account"
												:items="QBOIncomeAccountData"
												id="formdata-qbo-income-account"
												label=""
												solo
												flat
												outlined
												dense
												hide-details
												:loading="isQBOAccountLoading"
												:disabled="isQBOAccountLoading"
												:rules="[v => !!v || $t('field_required')]"
											>
											</v-autocomplete>
										</v-col>
										<v-col md="6" cols="12">
											<label class="form-label text-uppercase" for="formdata-expense-account">{{ $t('qbo_expense_account') }}</label>
											<v-autocomplete
												v-model="formData.qbo_expense_account"
												:items="QBOExpenseAccountData"
												id="formdata-qbo-expense-account"
												label=""
												solo
												flat
												outlined
												dense
												hide-details
												:loading="isQBOAccountLoading"
												:disabled="isQBOAccountLoading"
												:rules="[v => !!v || $t('field_required')]"
											>
											</v-autocomplete>
										</v-col>
										<v-col md="6" cols="12" v-if="formData.qbo_item_type==='Inventory'">
											<label class="form-label text-uppercase" for="formdata-inv-asset-account">{{ $t('qbo_inventory_asset_account') }}</label>
											<v-select
												v-model="formData.qbo_inventory_asset_account"
												:items="QBOInventoryAssetAccountData"
												id="formdata-inv-asset-account"
												label=""
												solo
												flat
												outlined
												dense
												hide-details
												:loading="isQBOAccountLoading"
												:disabled="isQBOAccountLoading"
												:rules="[v => !!v || $t('field_required')]"
											>
											</v-select>
										</v-col>
										<v-col md="6" cols="12" v-if="formData.qbo_item_type==='Inventory' && !isEditMode">
										<label class="form-label text-uppercase" for="formdata-asof-date">{{ $t('as_of_date') }}</label>
										<v-menu
											ref="asOfDateMenu"
											v-model="asOfDateMenu"
											close-on-content-click
											transition="scale-transition"
											max-width="290px"
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="formData.inv_start_date"
													label="As of Date"
													persistent-hint
													append-icon="mdi-calendar"
													v-bind="attrs"
													v-on="on"
													id="formdata-asof-date"
													solo
													dense
													flat
													outlined
													:rules="[v => !!v || $t('field_required')]"
												></v-text-field>
											</template>
											<v-date-picker
												v-model="formData.inv_start_date"
												no-title
												@input="asOfDateMenu = false"
											></v-date-picker>
										</v-menu>
									</v-col>
									<v-col md="6" cols="12" v-if="formData.qbo_item_type==='Inventory'">
										<label class="form-label text-uppercase" for="formdata-quantity">{{ $t('quantity') }}</label>
										<v-text-field
											v-model.number="formData.quantity_on_hand"
											solo
											outlined
											flat
											dense
											:label="$t('enter_quantity')"
											id="formdata-quantity"
											type="number"
											:rules="[v => !!v || $t('field_required')]"
										></v-text-field>
									</v-col>
									</v-row>
								</fieldset>
								<fieldset class="px-5 rounded pb-5">
									<legend class="rounded pa-2 mb-4">
										{{ $t('sales_information') }}
									</legend>

									<v-row>
										<v-col md="6" cols="12">
											<label class="form-label text-uppercase" for="formdata-sales-price">{{ $t('sales_price') }}</label>
											<v-text-field
												v-model.number="formData.sale_price"
												:label="$t('enter_sales_price')"
												solo
												outlined
												flat
												required
												dense
												id="formdata-sales-price"
												type="number"
												hide-details
											></v-text-field>
										</v-col>
										<v-col md="6" cols="12">
											<div class="d-flex align-center justify-space-between">
												<label class="form-label text-uppercase" for="formdata-income-account">{{ $t('income_account') }}</label>
												<v-icon @click="getAkauntingAccounts" v-if="incomeAccountData.length === 0">mdi-refresh</v-icon>
											</div>
											<v-select
												v-model="formData.de_income_account_value"
												:items="incomeAccountData"
												:rules="!formData.de_expense_account_value ? [v => !!v || $t('field_required')] : []"
												id="formdata-income-account"
												:label="$t('select_income_account')"
												solo
												flat
												outlined
												dense
												hide-details
												clearable
											>
											</v-select>
										</v-col>
									</v-row>
								</fieldset>

								<fieldset class="px-5 rounded mt-4 pb-4">
									<legend class="rounded pa-2 mb-4">
										{{ $t('purchase_information') }}
									</legend>
									<v-row>
										<v-col md="6" cols="12">
											<label class="form-label text-uppercase" for="formdata-purchase-price">{{ $t('purchase_price') }}</label>
											<v-text-field
												v-model.number="formData.purchase_price"
												:label="$t('enter_cost_price')"
												solo
												outlined
												flat
												required
												dense
												id="formdata-purchase-price"
												type="number"
												hide-details
											></v-text-field>
										</v-col>
										<v-col md="6" cols="12">
											<div class="d-flex align-center justify-space-between">
												<label class="form-label text-uppercase" for="formdata-expense-account">{{ $t('expense_account') }}</label>
												<v-icon @click="getAkauntingAccounts" v-if="expenseAccountData.length === 0">mdi-refresh</v-icon>
											</div>
											<v-autocomplete
												v-model="formData.de_expense_account_value"
												:items="expenseAccountData"
												:rules="!formData.de_income_account_value ? [v => !!v || $t('field_required')] : []"
												id="formdata-expense-account"
												:label="$t('select_expense_account')"
												solo
												flat
												outlined
												dense
												hide-details
												clearable
											>
											</v-autocomplete>
										</v-col>
									</v-row>
								</fieldset>
								
							</v-card>
						</v-col>
					</v-row>
				</v-form>
			</v-container>
		</v-card-text>

		<v-divider></v-divider>

		<v-card-actions class="d-none d-sm-flex">
			<v-btn color="primary" @click="onUpdateForm" class="text-capitalize btn-primary" v-if="isEditMode" :disabled="!formValid || isSaving" :loading="isSaving">{{ $t('update') }}</v-btn>
		  <v-btn color="primary" @click="onSaveForm(false)" class="text-capitalize btn-primary" v-if="!isEditMode" :disabled="!formValid || isSaving" :loading="isSaving">{{ $t('save') }}</v-btn>
			<v-btn outlined text @click="onSaveForm(true)" class="text-capitalize primary--text" v-if="!isEditMode" :disabled="!formValid || isSaving">{{ $t('save_add_another') }}</v-btn>
			<v-btn outlined text class="text-capitalize primary--text" @click="onClose" :disabled="isSaving">{{ $t('cancel') }}</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
		<v-card-actions class="d-flex d-sm-none">

			<v-btn color="primary" @click="onUpdateForm" class="text-capitalize btn-primary" v-if="isEditMode" :disabled="!formValid || isSaving" :loading="isSaving">{{ $t('update') }}</v-btn>
			<!-- <v-btn x-small color="primary" @click="onSaveForm(false)" class="text-capitalize btn-primary" v-if="!isEditMode" :disabled="!formValid || isSaving" :loading="isSaving">{{ $t('save_send') }}</v-btn>
			<v-btn x-small outlined text @click="onSaveForm(true)" class="text-capitalize" v-if="!isEditMode" :disabled="!formValid || isSaving">{{ $t('save_add_another') }}</v-btn> -->
			<v-menu offset-x v-else>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						dark
						v-bind="attrs"
						v-on="on"
						:loading="isSaving"
						class="d-flex align-center"
						>
						{{ $t('actions') }}
						<v-divider vertical class="mx-2"/>
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</template>
				<v-list dense class="text-uppercase">
					<v-list-item @click="onSaveForm(false)">
						<v-list-item-content>
							<v-list-item-title>{{ $t('save') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider/>
					<v-list-item @click="onSaveForm(true)">
						<v-list-item-content>
							<v-list-item-title>{{ $t('save_add_another') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<v-btn outlined text class="text-capitalize primary--text" @click="onClose" :disabled="isSaving">{{ $t('cancel') }}</v-btn>
		</v-card-actions>
	</v-card>
	<v-snackbar
    timeout="2000"
		vertical
		:color="snackbarOption.color"
		v-model="showSnackbar"
		bottom
  >
   <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
  </v-snackbar>
	</v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Service from '@/services/akaunting/akaunting.service';

export default {
  props: ["open", "isEditMode", "formValues"],
	data() {
		return {
			formData: {
				sku: '',
				name: '',
				sale_price: 0,
				purchase_price: 0,
				description: '',
				category: null,
				de_income_account_value: null,
				de_expense_account_value: null,
				qbo_enabled: 0,
				qbo_item_type: '',
				quantity_on_hand: 0,
				inv_state_date: null,
				qbo_inventory_asset_account: null,
				qbo_expense_account: null,
				qbo_income_account: null,
			},
			formValid: true,
			categoryData: [],
			taxData: [],
			itemTypeData: [],
			supplierData: [],
			showSnackbar: false,
			snackbarOption: {
				message: "",
				color: "primary"
			},
			isSaving: false,
			asOfDateMenu: null,
			defaultFields: null,
		}
	},

	created() {
		this.fetchItemCategory();
		this.defaultFields = JSON.parse(JSON.stringify(this.formData));
	},

  computed: {
		...mapState('accounting', [
			'incomeAccountLists',
			'expenseAccountLists',
			'QBOExpenseAccountLists',
			'QBOIncomeAccountLists',
			'isQBOAccountLoading',
			'QBOInventoryAssetAccountLists',
			'QBOInventoryExpenseAccountLists',
			'QBOInventoryIncomeAccountLists',
		]),
		...mapGetters('accounting', ['isQBOEnabled']),

		incomeAccountData: {
			get() {
				return Object.keys(this.incomeAccountLists).map(key => ({text: this.incomeAccountLists[key], value: JSON.stringify({[key]: this.incomeAccountLists[key]})}));
			}
		},
		expenseAccountData: {
			get() {
				return Object.keys(this.expenseAccountLists).map(key => ({text: this.expenseAccountLists[key], value: JSON.stringify({[key]: this.expenseAccountLists[key]})}))

			}
		},
		
		QBOIncomeAccountData: {
			get() {
				if(this.formData.qbo_item_type === '') {
					return [];
				}

				return this.formData.qbo_item_type === 'Inventory' ?
					this.QBOInventoryIncomeAccountLists.map(data => ( {text: data['Name'], value: JSON.stringify({Id: data['Id'], Name: data['Name']}) }))
				:
				this.QBOIncomeAccountLists.map(data => ( {text: data['Name'], value: JSON.stringify({Id: data['Id'], Name: data['Name']}) }));
			}
		},
		QBOExpenseAccountData: {
			get() {
				if(this.formData.qbo_item_type === '') {
					return [];
				}

				return this.formData.qbo_item_type === 'Inventory' ?
					this.QBOInventoryExpenseAccountLists.map(data => ( {text: data['Name'], value: JSON.stringify({Id: data['Id'], Name: data['Name']}) }))
					:
					this.QBOExpenseAccountLists.map(data => ({text: data['Name'], value: JSON.stringify({Id: data['Id'], Name: data['Name']}) }));
			}
		},
		QBOItemTypeData: {
			get() {
				return [
					'Service',
					'Inventory',
					'NonInventory'
				];
			}
		},
		QBOInventoryAssetAccountData: {
			get() {
				return this.QBOInventoryAssetAccountLists.map(data => ({text: data['Name'], value: JSON.stringify({Id: data['Id'], Name: data['Name']}) }));
			}
		},
		showDialog: {
			get() {
				return this.open;
			},
			set(value) {
				this.$emit("toggle", value);
			},
		},
  },

	watch: {
		formValues(values) {
			if(values && this.isEditMode) {
				const { 
					id,
					qbo_expense_account,
					qbo_income_account,
					sale_price, 
					qbo_item_type,
					description,
					name,
					purchase_price,
					qbo_item_id,
					de_expense_account_value,
					de_income_account_value,
					quantity_on_hand,
					qbo_asset_account,
					sku,
					category,
					inv_start_date,
				} = values;
				this.formData = {
					id,
					de_expense_account_value: de_expense_account_value,
					de_income_account_value: de_income_account_value,
					qbo_item_id,
					description,
					name,
					purchase_price,
					qbo_expense_account,
					qbo_income_account,
					qbo_item_type,
					quantity_on_hand,
					sale_price,
					qbo_inventory_asset_account: qbo_asset_account,
					sku,
					category: category ? JSON.stringify({
						id: category.data.id,
						name: category.data.name,
						type: category.data.type,
					}) : null,
					inv_start_date
				};
				this.onChangeQBOItem();
			} else {
				this.formData = {
					...this.defaultFields,
				};
			}
		}
	},

  methods: {
		...mapActions('accounting', ['createItemForm', 'getItemsData', 'getQBOAccounts', 'updateItemForm', 'getAkauntingAccounts']),

		fetchItemCategory() {
			Service.getAkauntingItemCategory().then(response => {
				if(response.data && response.data.data) {
					this.categoryData = response.data.data.data.map(data => ({
						text: data.name,
						value: JSON.stringify({
							id: data.id,
							name: data.name,
							type: data.type,
						})
					}));
				}
			}).catch(error => {
				this.categoryData = [];
			})
		},

		onChangeQBOItem() {
			// Fetch only once for these values.
			if(['Service', 'NonInventory'].includes(this.formData.qbo_item_type)) {
				if(!this.isEditMode) {
					this.formData = {
						...this.formData,
						quantity_on_hand: 0,
						inv_state_date: null,
						qbo_inventory_asset: null,
					};
				}
				// if(this.QBOIncomeAccountLists.length === 0 || this.QBOExpenseAccountLists.length === 0) {
					this.getQBOAccounts(this.formData.qbo_item_type);
				// }
			} else {
				if(!this.isEditMode) {
					this.formData = {
						...this.formData,
						qbo_expense_account: null,
						qbo_income_account: null,
					};
				}
				// if(!this.QBOInventoryExpenseAccountLists.length || !this.QBOInventoryIncomeAccountLists.length) {
					this.getQBOAccounts(this.formData.qbo_item_type);
				// }
			}
		},

		onClose() {
			this.$refs.itemForm.resetValidation();
			this.formData = {
				...this.defaultFields,
			};
			this.$emit("toggle");
		},

		async onSaveForm(isAddAnother = false) {
			if(this.isSaving) {
				return;
			}

			const validated = this.$refs.itemForm.validate();
			if(validated) {
				this.isSaving = true;
				try {

					const { data } = await this.createItemForm({...this.formData, qbo_enabled: this.isQBOEnabled});
					const message = data.message || 'Data was successfully saved.';
					this.snackbarOption = {
						icon: 'mdi-check',
						color: 'success',
						message,
					};

					this.$refs.itemForm.reset();
					if(!isAddAnother) {
						this.$emit("toggle", { created: true, message });
					} else {
						// Reload the table
						this.getItemsData();
					}
				} catch(error) {

					const { data } = error.response || { data: {  } };
					
					this.snackbarOption = {
						icon: 'mdi-alert-circle',
						color: 'error',
						message: data.message || "Could not save the data.",
					};

				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
		},

		async onUpdateForm() {
			if(this.isSaving) {
				return;
			}

			const validated = this.$refs.itemForm.validate();
			if(validated) {
				this.isSaving = true;
				try {

					const { data } = await this.updateItemForm({...this.formData, qbo_enabled: this.isQBOEnabled});
					const message = data.message || 'Data was successfully updated.';
					this.snackbarOption = {
						icon: 'mdi-check',
						color: 'success',
						message,
					};

					this.$refs.itemForm.reset();
					this.$emit("toggle", { message, updated: true });
				} catch(error) {

					const { data } = error.response || { data: {  } };
					
					this.snackbarOption = {
						icon: 'mdi-alert-circle',
						color: 'error',
						message: data.message || "Could not save the data.",
					};

				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
		}

  },
};
</script>

<style lang="scss" scoped>
	$form-label: #819FB2;
	$border-color: #21606bab;
	$btn-active-color: #0171a1;
	.dialog {
		z-index: 9999;
	}
	.form-label, .v-label{ 
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	fieldset {
		border: 1px solid $border-color;
		border-radius: 5px 5px 5px 5px;
	}

	fieldset legend {
		border: 1px solid;
		border-color: $border-color;
		background-color: #f5f5f5;
	}
	.btn-primary {
		background-color: $btn-active-color !important;
		color: #ffffff !important;
	}
	.z-index-front {
    z-index: 1;
  }
</style>